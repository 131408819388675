import { DirectusFile } from "./directus-file";
import { ImageFile } from "./image-file";
import { PageData } from "./page-data";
export interface HomePage {
  subtitle: string;
  intro: string;
  introductionCont: string;
  image: ImageFile;
  guide: DirectusFile;
}

export interface AwardAbout {
  goals: string;
  values: string;
  vision: string;
  targeted: string;
  message: string;
}

export interface AwardMembership {
  conditions: string;
  conditionImage: ImageFile;
  steps: string;
  stepsImage: ImageFile;
  arbitration: string;
  arbitrationImage: ImageFile;
}

export interface AwardBudget {
  title: string;

  goldenTitle: string;
  goldenDescription: string;
  goldenImage: ImageFile;

  silverTitle: string;
  silverDescription: string;
  silverImage: ImageFile;

  bronzeTitle: string;
  bronzeDescription: string;
  bronzeImage: ImageFile;
}

export interface News {
  id: string;
  title: string;
  date: string;
  image: ImageFile;
  content: string;
}

export type LandingPageData = PageData<
  {
    awardAbout: AwardAbout;
    awardMembership: AwardMembership;
    awardBudget: AwardBudget;
    news: News[];
  },
  HomePage
>;
