<section id="membership" class="bg-primary bg-opacity-10 position-relative">
  <div id="prize-membership"></div>
  <div class="container">
    <h2 class="mb-4">
      <span
        class="text-gradient subtitle h2 fw-bold"
        style="--gc-1: #00a651; --gc-2: #c29128"
      >
        جائزة التميز المدرسي
      </span>
    </h2>

    <div class="row">
      <div
        class="col-md-6 d-flex flex-column justify-content-center mb-4 mb-md-5"
      >
        <h6 class="h4 fw-bold mb-4">الشروط العامة للاشتراك</h6>
        <div [innerHTML]="awardMembership.conditions"></div>
      </div>
      <div class="col-md-6 mb-4 mb-md-5">
        <div
          class="img-gradient-wrapper"
          style="--gc-1: #00a651; --gc-2: #c29128"
        >
          <img
            [src]="awardMembership.conditionImage | imageLink"
            class="rounded-pill cover w-100"
            [alt]="awardMembership.conditionImage.title"
          />
        </div>
      </div>
      <div class="col-md-6 mb-4 mb-md-5">
        <div
          class="img-gradient-wrapper"
          style="--gc-1: #00a651; --gc-2: #c29128"
        >
          <img
            [src]="awardMembership.stepsImage | imageLink"
            class="rounded-pill cover w-100"
            [alt]="awardMembership.stepsImage.title"
          />
        </div>
      </div>
      <div
        class="col-md-6 d-flex flex-column justify-content-center mb-4 mb-md-5"
      >
        <h6 class="h4 fw-bold mb-4">خطوات الترشح للجائزة</h6>

        <div [innerHTML]="awardMembership.steps"></div>
      </div>

      <div
        class="col-md-6 d-flex flex-column justify-content-center mb-4 mb-md-5"
      >
        <h6 class="h4 fw-bold mb-4">تحكيم الجائزة</h6>
        <div [innerHTML]="awardMembership.arbitration"></div>
      </div>
      <div class="col-md-6 mb-4 mb-md-5">
        <div
          class="img-gradient-wrapper"
          style="--gc-1: #00a651; --gc-2: #c29128"
        >
          <img
            [src]="awardMembership.arbitrationImage | imageLink"
            class="rounded-pill cover w-100"
            [alt]="awardMembership.arbitrationImage.title"
          />
        </div>
      </div>
    </div>
    <div class="position-relative">
      <div id="prize-budget"></div>

      <h6 class="h5 fw-bold text-center mb-3 mt-5">فئات الجائزة</h6>
      <h2 class="mb-4">
        <span
          class="text-gradient subtitle h2 fw-bold"
          style="--gc-1: #00a651; --gc-2: #c29128; max-width: 100%"
        >
          {{ this.awardBuget.title }}
        </span>
      </h2>

      <div class="row mt-5">
        <div
          *ngFor="let awardBudgetAndCategory of awardBudgetAndCategories"
          class="col-xl-4 col-lg-4 col-md-6 supscription-col"
          [ngStyle]="{ 'margin-top': awardBudgetAndCategory.topMargin }"
        >
          <table class="table text-center table-striped table-borderless">
            <thead>
              <tr class="text-dark">
                <td style="font-weight: 700; font-size: 1.25rem">
                  {{ awardBudgetAndCategory.title }}
                </td>
              </tr>
              <tr class="text-white">
                <td>
                  <img
                    style="height: 400px; border-radius: 50%; font-size: large"
                    class="w-100"
                    [src]="awardBudgetAndCategory.image | imageLink"
                    [alt]="awardBudgetAndCategory.image.title"
                  />
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="px-1" style="font-weight: 500">
                  {{ awardBudgetAndCategory.description }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</section>
