import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { LandingPageData } from "src/app/interfaces/landing-page";

@Component({
  selector: "app-landing-about-us",
  templateUrl: "./landing-about-us.component.html",
  styleUrls: ["./landing-about-us.component.scss"],
})
export class LandingAboutUsComponent implements OnInit {
  constructor(private route: ActivatedRoute) {}
  awardAboutArray!: { title: string; content: string }[];
  ngOnInit(): void {
    const page = this.route.snapshot.data["page"] as LandingPageData;
    const awardAbout = page.data.awardAbout;

    this.awardAboutArray = [
      { title: "الرؤية", content: awardAbout.vision },
      { title: "الرسالة", content: awardAbout.message },
      { title: "القيم", content: awardAbout.values },
      { title: "الأهداف", content: awardAbout.goals },
      { title: "المستهدفون", content: awardAbout.targeted },
    ];
  }
}
