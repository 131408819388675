<app-navbar></app-navbar>
<div id="home">
  <div class="background-container">
    <img src="assets/images/prizebg.jpg" alt="" class="background-image" />
  </div>
  <div class="home-content">
    <div>
      <img src="assets/images/m4.jpg" class="into-image-sea" alt="">
    </div>
    <app-landing-about-us></app-landing-about-us>
    <app-landing-intro></app-landing-intro>
    <!-- <app-landing-news-activities></app-landing-news-activities> -->
    <app-landing-schools-membership></app-landing-schools-membership>
    <app-footer-links></app-footer-links>
    <app-copyright></app-copyright>
  </div>
</div>
