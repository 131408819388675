<section
  id="mubadara"
  class="mb-10 position-relative"
  style="background-color: #e8f7ef; margin-top: 210px;"
>
  <div id="prize-guide"></div>
  <img src="assets/images/mubadara-top.svg" class="pattern top" alt="" />
  <div class="container">
    <div class="row">
      <div class="col-md-6 d-flex flex-column justify-content-center">
        <p class="h4 fw-bold w-75">
          {{ homePageIntro.subtitle }}
        </p>
        <div>
          <p>
            <span class="visible-text" [innerHTML]="homePageIntro.intro"></span>
            <span
              class="hidden-text"
              [innerHTML]="homePageIntro.introductionCont"
              *ngIf="showHiddenText"
            ></span>
            <a class="show-more cursor-pointer" (click)="toggleHiddenText()">{{
              showHiddenText ? "أقل" : "المزيد"
            }}</a>
          </p>
        </div>

        <div>
          <a
            style="border: 1px solid lightgray"
            class="btn btn-light bg-transparent border-1 border-gray rounded-0"
            [href]="homePageIntro.guide | fileLink"
            target="_blank"
          >
            تحميل دليل الجائزة
          </a>
        </div>
      </div>
      <div class="col-md-6 mt-4 mt-md-0">
        <div class="slider">
          <img
            [src]="homePageIntro.image | imageLink"
            class="rounded-3 cover w-100 mx-2 school-prize-img"
            style="height: 430px"
            [alt]="homePageIntro.image.title"
          />
        </div>
      </div>
    </div>
  </div>
  <img src="assets/images/mubadara-bottom.svg" class="pattern bottom" alt="" />
</section>
