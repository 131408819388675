import {
  Component,
  OnDestroy,
  OnInit,
  ElementRef,
  ViewChild,
  HostListener,
} from "@angular/core";
import { ReplaySubject, takeUntil } from "rxjs";
import { ContactUsPage } from "src/app/interfaces/contact-us-page";
import { CMSService } from "src/app/services/cms.service";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-footer-links",
  templateUrl: "./footer-links.component.html",
  styleUrls: ["./footer-links.component.scss"],
})
export class FooterLinksComponent implements OnInit, OnDestroy {
  SCROLL_OFFSET = 300;

  // contact!: ContactUsPage;
  portalURI = environment.portalURI;
  @ViewChild("scrollTopBtn") scrollTopButtonRef!: ElementRef<HTMLButtonElement>;

  contact = {
    address:
      " المملكة العربية السعودية - الرياض - رمز بريدي 12622 - حي الوزارات 8184 - 3138",
    phone: "00966114800555",
    email: "abegs@abegs.org",
  };

  unsubscribe$ = new ReplaySubject(1);
  footerLinks = [
    {
      title: "بوابة المكتب",
      href: `${environment.portalURI}`,
      external: true,
    },
    // {
    //   title: "الرئيسية",
    //   href: "#home",
    //   selfLink: true,
    // },

    {
      title: "دليل الجائزة",
      href: "#prize-guide",
      selfLink: true,
    },
    {
      title: "عن الجائزة",
      href: "#prize-about",
      selfLink: true,
    },

    {
      title: "شروط وضوابط الجائزة",
      href: "#prize-membership",
      selfLink: true,
    },
    {
      title: "فئات الجائزة",
      href: "#prize-budget",
      selfLink: true,
    },
    {
      title: "تواصل معنا",
      href: "mailto:abegs@abegs.org",
      selfLink: true,
    },
    {
      title: "أخبار وفعاليات",
      href: "#news-and-events",
      selfLink: true,
    },
  ];

  socialMediaLinks = [
    {
      title: "youtube",
      iconClass: "fa-brands fa-youtube",
      href: "https://www.youtube.com/@AbegsOrg",
    },
    {
      title: "instagram",
      iconClass: "fa-brands fa-instagram",
      href: "https://www.instagram.com/_abegsorg",
    },
    {
      title: "facebook",
      iconClass: "fa-brands fa-facebook",
      href: "https://www.facebook.com/abegsorg/",
    },
    {
      title: "twitter",
      iconClass: "fa-brands fa-twitter",
      href: "https://twitter.com/abegsorg",
    },
  ];
  constructor(private cmsService: CMSService) {}

  ngOnInit() {
    // this.fetchContactUsDetails();
  }

  @HostListener("window:scroll")
  onWindowScroll() {
    if (!this.scrollTopButtonRef) return;

    if (window.scrollY > this.SCROLL_OFFSET) {
      this.scrollTopButtonRef.nativeElement.classList.add("shown");
    } else {
      this.scrollTopButtonRef.nativeElement.classList.remove("shown");
    }
  }

  fetchContactUsDetails() {
    this.cmsService
      .getContactUsDetails()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: (result) => {
          this.contact = result;
        },
      });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next(1);
    this.unsubscribe$.complete();
  }
}
