import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { LandingPageComponent } from "./components/pages/landing-page/landing-page.component";
import { NewsActivitiesPageComponent } from "./components/pages/news-activities-page/news-activities-page.component";
import { SingleNewsPageComponent } from "./components/pages/news-activities-page/single-news-page/single-news-page.component";
import { ContactUsComponent } from "./components/pages/contact-us/contact-us.component";
import { LandingPageResolver } from "./resolvers/landing-page.resolver";
import { NewsPageResolver } from "./resolvers/news-page.resolver";
import { SingleNewsPageResolver } from "./resolvers/single-news-page.resolver";

const routes: Routes = [
  {
    path: "",
    component: LandingPageComponent,
    resolve: { page: LandingPageResolver },
  },
  // {
  //   path: "news",
  //   component: NewsActivitiesPageComponent,
  //   resolve: { page: NewsPageResolver },
  // },
  // {
  //   path: "news/:singleNewsId",
  //   component: SingleNewsPageComponent,
  //   resolve: { page: SingleNewsPageResolver },
  // },
  // { path: "contact-us", component: ContactUsComponent },

  { path: "**", redirectTo: "" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      onSameUrlNavigation: "reload",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
